.vacancy-list {
  h2 {
    font-size: 48px;
    text-transform: uppercase;
  }
  h3 {
    font-size: 36px;
    text-transform: uppercase;
  }
  .controls {
    padding-top: 16px;
    padding-bottom: 24px;
  }
  .col-1 {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #000;
    justify-content: space-between;
    h3 {
      font-weight: 400;
    }
    .item {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #000;
      padding: 16px 0 44px;
      ul {
        margin: 0;
        padding-left: 16px;
        width: 50%;
        font-size: 18px;
        line-height: 23px;
        li {
          margin-bottom: 16px;
          ul {
            margin-top: 8px;
          }
        }
      }
      .img {
        width: 50%;
        img {
          max-width: 100%;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  .hidden {
    color: #000;
    position: relative;
    z-index: -1;
  }
}
