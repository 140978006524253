.btn {
  background: transparent;
  border: 2px solid #fff;
  padding: 20px 44px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  &-black {
    border-color: #000;
    color: #000;
  }
}

.btn-close {
  border: 0;
  outline: none;
  background: transparent;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.btn-arrow {
  margin-left: 24px;
  svg {
    width: 12px;
  }
}

.jobot-forms-rc-select-multiple .jobot-forms-rc-select-selector {
  padding: 9px 10px;
  border-radius: 0;
}

.index__fileControls {
  label {
    padding: 10px!important;
    width: auto!important;
    background: transparent!important;
    &:focus {
      outline: none;
    }
  }
  .index__formBtn {
    margin: 10px 0;
    height: 45px;
  }

  .spinner {
    border-color: #aaa !important;
  }
}

.index__fileList {
  .index__fileButtonGroup {
    margin-left: 12px;
  }
}

.button-text {
  text-transform: uppercase;
}

.index__formSectionBtn,.index__fileControls label {
  background: transparent;
  font-size: 16px;
  border: 2px solid #000;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    background: transparent;
  }
}

.index__formWrapper {
  font-family: "Futura PT", sans-serif;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 100px;
  h2 {
    margin: 50px 0 10px;
  }
  input {
    font-size: 20px;
    line-height: 1.3;
  }
  form{
    label.index__formLabel, label.index__formLabelRequired {
      width: 50%;
    }
    label.index__formLabel + div, label.index__formLabelRequired + div {
      width: 50%;
    }
    & > div > div {
      .jobot-form-field-text, .jobot-form-field-date {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
    & > div {
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      &:first-child {
        & > div {
          display: flex;
          label, & > div:nth-child(2) {
            width: 50%;
          }
          label {
            padding-right: 30px;
            font-size: 18px;
            line-height: 1.1;
            margin-bottom: 10px;
            margin-top: 10px;
            display: block;
          }
        }
      }
      & > div {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        label, & > div:nth-child(2):not(.index__formSectionRow) {
          width: 50%;
        }
        label {
          padding-right: 30px;
          font-size: 18px;
          line-height: 1.1;
          margin-bottom: 10px;
          margin-top: 10px;
          display: block;
        }
        .index__error {
          width: 100%;
          position: absolute;
          bottom: 0;
          font-size: 15px;
          margin-bottom: -8px;
        }
      }
    }
  }
  .jobot-forms__value-container {
    padding: 0 8px;
    min-height: 50px;
    & > div {
      margin: 0;
    }
  }
  .jobot-forms__formDateSelect {
    display: flex;
    input {
      width: 100%;
      &::placeholder {
        color: #a3a3a3;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    & > div {
      flex: 1 0 auto;
    }
  }
  .jobot-forms__indicator-separator {
    display: none;
  }
  .jobot-forms__control--is-focused {
    border-color: #ccc;
    &:hover {
      border-color: #ccc;
    }
  }
  .jobot-forms__menu {
    .jobot-forms__option {
      font-size: 20px;
    }
  }
  .jobot-form-field-textarea {
    flex-direction: column;
    label, & > div {
      width: 100%!important;
    }
  }
  .index__formSection {
    display: block;
    .index__formSectionRow {
      & > div {
        position: relative;
        & > div {
          display: flex;
          flex-wrap: wrap;
          & > div {
            width: 50%;
            .index__formDateSelect {
              .index__formInput {
                &:first-child:last-child {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
      .index__formSectionBtn,.index__fileControls label {
        background: transparent;
        font-size: 16px;
        border: 2px solid #d2233b;
        color: #d2233b;
        text-transform: uppercase;
        &:hover {
          background: transparent;
        }
      }

    }
  }
  .jobot-form-field-file {
    .index__fileItem > div:first-child{
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }
  }
}

.index__formInput {
  min-height: 50px;
  border-radius: 0;
  font-size: 16px;
  font-weight: normal;
}

.index__formBtn {
  background: transparent;
  color: #000;
  border: 2px solid #000;
  cursor: pointer;
  &:hover {
    background: transparent;
  }
  &:disabled {
    background: transparent;
    border-color: #9e9e9e;
    color: #9e9e9e;
  }
}

.index__formSection {
  .index__formSectionRow {
    border-top: 1px solid;
    padding-top: 30px;
    &:first-child {
      border: 0;
    }
  }
}

body {
  .jobot-forms_select-count-lines-item {
    font-size: 20px;
  }

  .jobot-forms__control {
    border-radius: 0;
  }
  .checkbox-wrapper .rc-checkbox-checked .rc-checkbox-inner {
    border-color: #D2233C;
    background-color: #D2233C;
  }
  .rc-checkbox:hover .rc-checkbox-inner, .rc-checkbox-input:focus + .rc-checkbox-inner {
    border-color: #D2233C;
  }
  .rc-checkbox-inner {
    width: 40px;
    height: 40px;
    border-radius: 0;
    transition: none;
  }
  .rc-checkbox-checked {
    .rc-checkbox-inner {
      transition: none;
      &:after {
        left: 14px;
        top: 8px;
        width: 8px;
        height: 16px;
        animation-duration: 0s;
      }
    }
  }
  .rc-checkbox-inner:after {
    animation-duration: 0s;
  }
  .checkbox-wrapper {
    display: flex;
    width: 100%!important;
    align-items: center;
    margin-bottom: 20px;
    line-height: 1.1;
    .checkbox-label {
      padding-left: 20px;
      cursor: pointer;
    }
  }
  .checkbox-wrapper-required:before {
    top: 0;
  }
}

.textarea {
  border-radius: 0;
  margin-bottom: 14px;
}

.wrap-form {
  margin-top: 80px;
  h1 {
    margin-bottom: 40px;
  }
}

.jobot-forms__menu {
  .jobot-forms__option {
    outline: none;
  }
  .jobot-forms__option--is-focused {
    background-color: #ffd2d9;
    &:active {
      background-color: #ffd2d9;
    }
  }
  .jobot-forms__option--is-selected {
    background-color: #d2233d;
    color: #fff;
  }
}

.jobot-form-field-choice {
  display: flex;
  width: 100%;
  & > div:last-child {
    width: 50%;
  }
}

.index__formWrapper form > div > div label.checkbox-wrapper {
  display: flex;
}

.select-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAACYCAAAAAA+MrOQAAAAAXNSR0IArs4c6QAAAY9JREFUaN7t1mtXgkAQBmD//2/bxbuVVGqW2EmNVEJReDuZl8FbLqwfOr37cZmZB/YyhwKuPAoECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAgb8MJMEoSC7IiXw/ygJMa0qpqv9bxrKjldLdxBgIHbUaz+cTwupPWMsYcNV6PJx7uUlpEzY1BYqbTHW7OBk+dLZR/eyAqs1ORHu7GHPAFcml49/fFiHmSzRzRLYzOgyNm7J+2/yYfohFUvpgBaK6rH9vfkyBoCxLdNKBn5UzDy9tFfPUS7oxTn2el7UXLe+k0Ng1hDctN2iYvdklLSlUwvX0i5wtTXJ101St4nilPsq5apizXQ/kaujB/rrdLJATwFjup+rNaqnjGSM3gDB1IuX9U13AAoCooY4O/Qo7AGL3WH3Hhy0AeDqsXw5gEUBf79Wvz2EVwHtqd1VzCcsAprL3tRNYBzDf3QAPuAKwvcNG3c0EQNL73ohGgGsBwMIf5CnPv2sCBAgQIECAAAECBAgQIECAAAECBAgQIPAPgS89JmGgMbm6RQAAAABJRU5ErkJggg==');
  background-size: 24px 38px;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0;
  -webkit-padding-end: 20px;
  padding-right: 24px!important;
}

.jobot-forms-rc-select-single {
  &:not(.jobot-forms-rc-select-customize-input) {
    .jobot-forms-rc-select-selector {
      background: #fff;
      border-radius: 0;
      min-height: 50px;
      .jobot-forms-rc-select-selection-item {
        top: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 55px);
      }
    }
  }
}

.jobot-forms-rc-select-single .jobot-forms-rc-select-selector .jobot-forms-rc-select-selection-placeholder {
  top: 15px;
}

.jobot-forms-rc-select-item {
  line-height: 1.5;
}

.jobot-forms-rc-select-item-option {
  cursor: pointer;
}

.jobot-forms-rc-select-item-option-active {
  background: #D2233C;
  color: #fff;
}

.jobot-forms-rc-select-item-option-selected {
  background-color: #d2233b;
}

.jobot-forms-rc-select.jobot-forms-rc-select-single .jobot-forms-rc-select-selection-search-input {
  height: 30px;
  line-height: 50px;
}

.jobot-forms-rc-select-allow-clear .jobot-forms-rc-select-clear {
  line-height: 50px;
}

.jobot-forms-rc-select-show-arrow .jobot-forms-rc-select-arrow {
  top: 13px;
}

.jobot-forms-rc-select-dropdown {
  border-radius: 0;
}

.jobot-forms-rc-select-dropdown {
  z-index: 10000;
}

.rc-virtual-list-holder {
  overflow-y: auto!important;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0!important;
    background: transparent;
    display: none;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: transparent!important;
    -webkit-box-shadow: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: transparent!important;
  }
}

.jobot-forms-rc-select-focused .jobot-forms-rc-select-selector {
  border-color: #ccc;
}

.react-tel-input  {
  .form-control {
    border: none;
  }

  .flag-dropdown {
    border: none;
    background-color: transparent;
  }
}

body .opd-html-form {
  line-height: inherit;
  font-weight: 400;
  p {
    strong {
      display: block;
      margin: 30px 0 20px;
      font-size: 20px;
    }
  }
  input[type="checkbox"] {
    border-radius: 0;
    cursor: pointer;
    &:checked {
      background: #D2233C;
      border-color: #D2233C;
      &:before {
        top: 0;
        left: 0;
      }
    }
  }
}
